<div class="miFormulario" [style.background-color]=colorCuerpo>
  <h2 class="titulo" [style.color]=colorTextoCuerpo>Política de Cookies</h2>
  <br>    
  <div class="contenido" [style.color]=colorTextoCuerpo>    
    {{politicaCookies}}
  </div>  

  <br>

  <div class="agregar">
    <button mat-raised-button (click)="aceptar()" class="btn-confirm">Guardar</button>
    <button mat-raised-button (click)="cancelar()" class="btn-cancel">Cancelar</button>
  </div>
 <!-- <br> -->
</div>