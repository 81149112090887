export class CRespuesta {

    public bolEstado:boolean;
    public strUsuario:string;
    public strRol:string;
    public strMensaje:string;    
    
}


