import {User} from "./user.model";
import { Token } from "./token";

export class Session {
  public strToken: string;
  public strMensaje:string;
  public strCookie?:string;
  public intMesaId?:number;
  public expires:number;
  public token:Token;
  public user: User;

  //Datos generales de la aplicacion

  public strNombre?:string;
  public strNombreTitulo?:string;

}
