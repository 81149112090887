export class Establecimiento {

    public intId?:number;
    public strNombre?:string;
    public strDescripcion?:string;
    public strNif?:string;
    public strEmail?:string;
    public strWeb?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strDireccion?:string;
    public strCp?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strPais?:string;
    public strCondicionesGenerales?:string;
    public strMapa?:string;
    public strEslogan?:string;
    public strActivo?:string;
    public strUserId?:string;
    public decPortes?:number;
    public decPedidoMinimo?:number;
    public strCorreoUsuario?:string;
    public strCorreoClave?:string;
    public strCorreoSmtp?:string;
    public strCorreoSmtpPort?:string;
    public strCorreoSmtpSsl?:string;
    public intNumTarifas?:number;
    public strColorMenu?:string;
    public strColorCuerpo?:string;
    public strColorTextoCuerpo?:string;
    public strColorTextoMenu?:string;
    public intDiasHabituales?:number;
    public strLogo?:string;
    
}
