
<mat-card >
  <form [formGroup]="loginForm">
    <fieldset>
      <legend> <img src="../../assets/logotus.png" alt="" class="miLogotus"> </legend>

      <section>
        <div class="col-3">
          <input class="effect-2" formControlName="username" type="text" [(ngModel)]="strUserName" name="strUserName" placeholder="Usuario">
          <span class="focus-border"></span>
        </div>
        <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.username.hasError('required')">*Campo necesario</mat-hint>
      </section>

      <section>
        <div class="col-3">
          <input class="effect-3" type="password" *ngIf="mostrarPassword" formControlName="password" [(ngModel)]="strPassword" name="strPassword" placeholder="Contraseña">
          <input class="effect-3" type="text" *ngIf="ocultarPassword" formControlName="password" [(ngModel)]="strPassword" name="strPassword" placeholder="Contraseña">
          <span class="focus-border"></span>
          <mat-icon class="Contraseña" (click)="showPassword()" *ngIf="mostrarPassword">visibility</mat-icon>
          <mat-icon class="Contraseña" (click)="hiddenPassword()" *ngIf="ocultarPassword">visibility_off</mat-icon>
        </div>
        <mat-hint class="formatError" style="color: red;" *ngIf="submitted && loginForm.controls.password.hasError('required')">*Campo necesario</mat-hint>
      </section>      
      
      <div>
        <span *ngIf="isLoginError" class="error-message">
          <mat-icon>no_encryption</mat-icon> Usuario o contraseña incorrectos
        </span>
      </div>

      <div>
        <button mat-raised-button (click)="submitLogin()" class="miBoton">Acceder</button>
      </div>

      <span class="recuperacion">
          <a href="../login-recuperacion" routerLinkActive="active-link"> ¿Has olvidado la contraseña? </a>
       </span>
      
      </fieldset>
   </form>

<div class="loader2" *ngIf="showSpinner"></div>

<br><br> 

    <div *ngIf="mostrar">
        <h2><i> ¿Eres un nuevo usuario? </i></h2>
        <br>
        <div>
          <button mat-raised-button (click)="createUserLogin()" class="miBoton2">Usuario nuevo</button>
        </div>
    </div>

  </mat-card>  

  