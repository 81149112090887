import { Component, OnInit } from '@angular/core';
import { CRespuesta } from './shared/crespuesta';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CRespuestaService } from './shared/CRespuesta.service';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Session } from 'src/app/models/session.model';
import { Token } from 'src/app/models/token';
import { User } from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public miCRespuesta:CRespuesta=new CRespuesta();
  isLoginError : boolean = false;
  texto:string;
  public userAcces:User[]=[];
  strUserName:string;
  strPassword:string;
  token:string;
  miSesion:Session=new Session();
  misDatosSesion:Token=new Token();

  showSpinner: boolean = false;
  mostrar:boolean=true;

  public loginForm: FormGroup;
  public submitted: Boolean = false;

  mostrarPassword:boolean=true;
  ocultarPassword:boolean=false;

  title:string;

  constructor(private formBuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,
    private CRespuestaService:CRespuestaService) { }

  ngOnInit() {

    this.loginForm  = this.formBuilder.group({

      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),

      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'),
      ])),

    });
  }

  
submitLogin(){
  this.submitted = true;
  this.isLoginError = true;

  if(this.loginForm.valid){
    this.submitted = false;

    this.CRespuestaService.findCRespuesta(this.strUserName,this.strPassword).pipe(first()).subscribe(datos=>{
      //console.log(datos)
      if(datos!=null){
        let miUserName=datos.userName;
        let miToken=datos.access_token;
        this.token=datos.access_token;      

        this.misDatosSesion=datos as Token;
  
        this.CRespuestaService.findSession(miUserName,miToken).subscribe(data =>{
          //console.log(data)

          data.token=this.misDatosSesion;
          data.strToken=this.token;
        
          if(data.strMensaje === 'OK'){
            //console.log(data)
            let miOtroToken=data.strToken;

            /********************* Aqui recuperaro los datos del nombre y titulo de la app de la sesion  ******************/

            this.CRespuestaService.getConfiguraciones(miOtroToken).pipe(first()).subscribe(datos => { 
              //console.log(datos)
              data.strNombre=datos[0].strNombre;
              data.strNombreTitulo=datos[0].strNombreTitulo;
              //this.storageService.setCurrentSession(this.storageService.getCurrentSession());
              //console.log(data)
              this.correctLogin(data);  
          });
            
            
          }

        })
       }
     },
     (err : HttpErrorResponse)=>{
       this.isLoginError = true;
     });
     this.isLoginError = false;
     

  }
  
 }

 public getTitle(){
  this.title = document.title = this.storageService.getCurrentSession().strNombreTitulo;
  //console.log(this.title);
}

  private correctLogin(data: Session){
    this.storageService.setCurrentSession(data);

    this.showSpinner = true;
    this.mostrar=false;

    //this.getTitle();

    this.router.navigate(['home']);
         /* setTimeout(() => {
            this.showSpinner = false;
            if (data.user.strRolName==="Administrador"){
              this.router.navigate(['/list-clientes']);
            }else{
              this.router.navigate(['/datos-cliente'])
            }
          }, 2000);     */
  }

  createUserLogin(){
    this.router.navigate(['/create-user'])
  }


  showPassword(){
    this.mostrarPassword=false;
    this.ocultarPassword=true;
  }

  hiddenPassword(){
    this.mostrarPassword=true;
    this.ocultarPassword=false;
  }

}
