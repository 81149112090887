// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl : 'https://webservice.infitec.es/resttpvwa',
  //baseUrl : 'http://192.168.1.135/resttpvwa',
  // baseUrl : 'http://localhost:35113',
  baseUrl : 'https://resttpvwa.infitec.es',

  //Variables cookies
  strCookie:"strCookiePanelMesa",
  intIdMesa:"intMesaIdPanelMesa",
  // intIdEstablecimiento:"intIdEstablecimiento",
  // intIdPro:"intIdPro",
  strColorCuerpo:"strColorCuerpoPanelMesa",
  strColorTextoCuerpo:"strColorTextoCuerpoPanelMesa",
  strNombreMesa:"strMesaPanelMesa",
  strNombreZona:"strZonaPanelMesa",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
