import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material';
import { PoliticaCookiesComponent } from '../politica-cookies/politica-cookies.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Establecimiento } from 'src/app/models/establecimiento';
import { DataCookie } from 'src/app/models/data-cookie';
import { BotonesService } from 'src/app/services/botones.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  cookieNombre = 'CookieNavegadorPanelControlMesa';

  //NO PUEDO COGER EL NOMBRE DE LA SESION PORQUE EN EL LOGIN NO HAY SESION INICIADA
  //nombreApp:string=this.storageService.getCurrentSession().strNombre;
  nombreApp:string;
  tituloApp:string;
  aceptCookies:boolean=true;
  

  strColorCuerpo:string=environment.strColorCuerpo;
  strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  // MuestraEst=false;

  colorCuerpo:string;
  colorTextoCuerpo:string;
  miCooKie:string=environment.strCookie;
  miEstablecimiento:Establecimiento=new Establecimiento();
  cookie:string;
  establecimiento:string;
  midataCookieAux:DataCookie=new DataCookie();

  constructor(private dialog: MatDialog,private miservicio:BotonesService,private route: ActivatedRoute,
    private cookieService: CookieService ) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
        this.establecimiento=params["est"];
      });
     }

  ngOnInit() {
    // console.log(this.cookie)
    // if(this.cookie===undefined){
    //   this.aceptCookies=false;
    //   this.MuestraEst=false;
    // }else{
      // this.aceptCookies=true;
      // this.MuestraEst=false;
      // this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
      // this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);
      
      // if(this.establecimiento===undefined || this.establecimiento===""){

      // }
      
  
      // if(this.IsCookieExists){
      //   this.aceptCookies=false;
      //   // this.removeCookies();
      // }
      // else{
      //   //Recupero los datos del establecimiento
      //   this.getNombreApp();
      // }
    // }

    // setTimeout(()=>{
      // this.getNombreApp();
    // },1000)
    
    // this.datosEstablecimiento();
    //this.cookieService.delete('Test');
    //console.log(this.cookieValue2);    
  }

  // datosEstablecimiento(){
  //   this.miservicio.getEstablecimiento(sessionStorage.getItem(this.miCooKie)).subscribe(datos=>{     
  //     //console.log(this.listEstablecimiento);
  //     this.colorCuerpo="#"+datos[0].strColorCuerpo;
  //     this.colorTextoCuerpo="#"+datos[0].strColorTextoCuerpo;
  //   })
  // }

  getNombreApp(){
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.establecimiento);
    this.midataCookieAux=JSON.parse(this.cookieService.get(this.cookieNombre+this.establecimiento));
    if(IsCookieExists){
      this.aceptCookies=false;
    }
    this.miservicio.getEstablecimiento(sessionStorage.getItem(this.miCooKie)).subscribe(datos => { 
      if(datos.miRespuesta.booOk){
        // console.log(datos)
        // this.MuestraEst=true;
        this.miEstablecimiento=datos.miListEstablecimiento[0];

        
        // console.log(this.miEstablecimiento)

        // if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
        //   this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        // }
        // if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
        //   this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        // }
        // if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
        //   this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        // }
        // if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
        //   this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        // }
        // if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
        //   this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        // }
        // if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
        //   this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        // }
      }else{

      }
    })
  }

  cancelar(){
    this.aceptCookies=false;
  }

  aceptaCookies(){
    if(this.establecimiento===undefined || this.establecimiento===""){
      this.establecimiento
    }
    this.cookieService.set(this.cookieNombre,'PanelControlMesa',365,'',window.location.hostname );
    this.aceptCookies=false;
  }

  removeCookies(){
    this.cookieService.delete(this.cookieNombre,'',window.location.hostname);
  }

  cookies(){
    let dialogRef = this.dialog.open(PoliticaCookiesComponent, {
      width:'600px',
      // data:this.strCookie
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.cookieService.set(this.cookieNombre,'PanelControlMesa',365,'',window.location.hostname);
        this.aceptCookies=false;
      }
    })
  }

}
