import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { DataCookie } from 'src/app/models/data-cookie';
import { BotonesService } from 'src/app/services/botones.service';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.scss']
})
export class PoliticaCookiesComponent implements OnInit {

  colorCuerpo:string;
  colorTextoCuerpo:string;
  politicaCookies:string;
  // miCooKie:string=environment.strCookie;
  midataCookie:DataCookie=new DataCookie();
  cookieNombre = 'CookiePanelControlMesa';

  constructor(private miservicio:BotonesService, private cookieService: CookieService, private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    // console.log(this.data)
    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.data))
    // this.datosEstablecimiento();
    this.colorCuerpo=this.midataCookie.strColorCuerpo;
    this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
    this.getPoliticaCookies();
  }

  datosEstablecimiento(){
    this.miservicio.getEstablecimiento(this.data).subscribe(datos=>{     
      // console.log(datos[0])
      this.colorCuerpo="#"+datos[0].strColorCuerpo;
      this.colorTextoCuerpo="#"+datos[0].strColorTextoCuerpo;
    })
  }

  getPoliticaCookies(){
    this.miservicio.getPoliticaCookies(this.data).subscribe((datos:any)=>{
      // console.log(datos)
      this.politicaCookies=datos.strPoliticaCookies;
    })
  }

  aceptar(){
    this.thisDialogRef.close(true);
  }

  cancelar(){
    this.thisDialogRef.close(false);
  }

}
