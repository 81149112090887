<!-- <footer> -->
  <!-- <p [style.color]=colorTextoCuerpo *ngIf="MuestraEst"> -->
      <!-- {{nombreApp}} Copyright © 2018 Infitec -->
      <!-- {{miEstablecimiento.strNombre}} {{miEstablecimiento.strTelefono1}}  {{miEstablecimiento.strTelefono2}}  {{miEstablecimiento.strDireccion}}
      {{miEstablecimiento.strPoblacion}}  {{miEstablecimiento.strCp}}  {{miEstablecimiento.strProvincia}}
  </p> -->
<!-- </footer> -->

<div class="misCookies" *ngIf="aceptCookies">
    <span class="close" (click)="cancelar()">&times;</span>
    <br>
    <div>
        Esta web inserta cookies propias para facilitar tu navegación. 
        Si continúas navegando consideramos que aceptas su uso. 
        Puedes cambiar la configuración u obtener más información en nuestra  <a (click)="cookies()">politica de cookies</a>.
    </div>
  
    <section>
        <button (click)="aceptaCookies()">Aceptar</button>
    </section>
  </div>