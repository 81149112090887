<!-- <div class="body" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo> -->
  <div  class="contenedor" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo *ngIf="muestraContenido">
    <!-- <table>    
      <tr *ngFor="let boton of listBotones">
          <td (click)="pulsa(boton)">
                <h2>{{boton.strNombre}}</h2>  
              <img src="data:image/gif;base64,{{boton.strImagen}}">  
          </td>
        </tr>     
    </table> -->
    <div class="ZonaMesa">
      <div class="bandaImg"><img src="data:image/gif;base64,{{miEstablecimiento.strLogo}}" style="max-width: 100%;"></div>
      
      
      <!-- <div class="banda" [style.color]=colorTextoCuerpo>
        {{strMesa}} -->
        <!-- <a href="https://web.whatsapp.com/send?text=www.google.com" data-text="Take a look at this awesome website:" class="wa_btn wa_btn_s">Share1</a> -->
        <!-- <a href="whatsapp://send?text=www.google.com" data-text="Take a look at this awesome website:" class="wa_btn wa_btn_s">WhatsApp</a> -->
      <!-- </div> -->
      <p [style.color]=colorTextoCuerpo style="width: 90%; text-align: center; margin: 0 auto; font-size: 14px;">
        <!-- {{nombreApp}} Copyright © 2018 Infitec -->
        {{miEstablecimiento.strNombre}} {{miEstablecimiento.strTelefono1}}  {{miEstablecimiento.strTelefono2}}  {{miEstablecimiento.strDireccion}}
        {{miEstablecimiento.strPoblacion}}  {{miEstablecimiento.strCp}}  {{miEstablecimiento.strProvincia}}
      </p>
      <br>
      <div class="banda" [style.color]=colorTextoCuerpo>
        {{strZona}} - {{strMesa}}
      </div>
    </div>
    <br>
    
    <ul [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>
      <li *ngFor="let boton of listBotones">
        <div class="btn" (click)="pulsa(boton)">
          <img src="data:image/gif;base64,{{boton.strImagen}}">
          <div style="font-weight: 700; font-size: 18px;">{{boton.strNombre}}</div>
        </div>
        <br>
      </li>
    </ul>
  </div>
<!-- </div> -->

<section *ngIf="myLoader"  class="miLoader">

  <svg viewBox="-2000 -1000 4000 2000">
    <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
    </path>
    <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
    </use>
  </svg>
</section>

<div class="misCookies" *ngIf="aceptCookies">
  <span class="close" (click)="cancelar()">&times;</span>
  <br>
  <div>
      Esta web inserta cookies propias para facilitar tu navegación. 
      Si continúas navegando consideramos que aceptas su uso. 
      Puedes cambiar la configuración u obtener más información en nuestra  <a (click)="cookies()">politica de cookies</a>.
  </div>

  <section>
      <button (click)="aceptaCookies()">Aceptar</button>
  </section>
</div>