import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BotonesComponent } from './components/botones/botones.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { LoginComponent } from './components/login/login.component';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { VentanaEmergenteComponent } from './ventana-emergente/ventana-emergente.component';

const routes: Routes = [
  /************ componentes que se pueden cargar sin loguearse ***********/
  { path: 'login', component: LoginComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'ServciosMesa', component: BotonesComponent },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },

  /************************* Estos son rutas por defecto ************************/

  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: '**', redirectTo: '/inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
